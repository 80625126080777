<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" v-model="form.search" @keypress.enter="list" v-focus></b-form-input>
      </b-input-group>

      <b-row class="mb-2">
        <b-col>
          <small>정렬방식</small>
          <b-form-radio-group v-model="form.sort" :options="options.sort"></b-form-radio-group>
        </b-col>
        <b-col xl="3" lg="6">
          <small>소속</small><br/>
          <b-button class="mr-3" size="sm" variant="warning" @click="toggleStatus">전체</b-button>
          <b-form class="mt-2" inline>
            <b-form-checkbox-group name="statusMulti" v-model="form.teams">
              <b-form-checkbox v-for="e in teams" :key="e.value" :value="e.value">
                {{ e.text }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form>
        </b-col>
        <b-col xl="3" lg="6">
          <small>승인여부</small>
          <b-form-radio-group v-model="form.approved" :options="options.approved">
          </b-form-radio-group>
        </b-col>
        <b-col xl="3" lg="6">
          <small>마지막 로그인 시각</small><br/>
          <b-form inline>
            <b-form-input class="text-center w-160px" v-model="form.login_dt_from" placeholder="2020-04-01 00:00:00"></b-form-input>
            ~
            <b-form-input class="text-center w-160px" v-model="form.login_dt_to" placeholder="2020-04-01 00:00:00"></b-form-input>
          </b-form>
        </b-col>
      </b-row>

      <b-button class="m-1" variant="primary" @click="list">검색</b-button>
    </b-card>

    <b-modal :title="T('유저상세')" size="xl" v-model="modal.detail" ok-title="저장" cancel-title="닫기" @hide="modalHide">
      <b-row>
        <b-col cols="12" lg="9">
          <div class="hr-title"><b>{{ T(`기본정보`) }}</b></div>
          <hr/>
          <b-row>
            <b-col class="mb-2" cols="6" lg="4">
              <small>ID</small><br/>
              <b-input plaintext v-model="item.id"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Name</small><br/>
              <b-input v-model="item.name"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Group</small><br/>
              <b-form-select v-model="item.teamId" :options="[
                  {text:'(팀을 선택해주세요)', value:'', disabled: true},
                  ...teams
                ]"></b-form-select>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Email</small><br/>
              <b-input v-model="item.email"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>휴대폰 번호(숫자로만 입력해주세요)</small><br/>
              <b-input v-model="item.mobile"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>근무형태</small><br/>
              <div v-if="item.workType" class="col-form-label">{{options.workType.find(e => e.value === item.workType).text}}</div>
            </b-col>

          </b-row>
          <b-alert variant="info" :show="item_org.mobile !== item.mobile || item_org.email !== item.email">
            Email 이나 휴대폰 번호를 바꾸면 로그인 2차 인증이 불가할 수 있으니 주의해주시기 바랍니다.
          </b-alert>
          <b-row>
            <b-col class="mb-2">
              <small>이미지</small><br/>
              <b-input v-model="item.img"></b-input>
            </b-col>
          </b-row>

          <div class="hr-title"><b>권한설정</b></div>
          <hr/>
          <div class="mb-3">
            R: 읽기권한, W: 쓰기권한, WB: 대량(batch) 쓰기권한, X: 실행(알림톡 발송 등 취소 불가능한) 권한
          </div>
          <b-row v-for="r in Object.keys(ROLE_INFO)">
            <b-col cols="2" class="mt-1 mb-2">
              {{ ROLE_INFO[r].title }}
              <i class="fa fa-question-circle" v-b-tooltip="ROLE_INFO[r].text"></i>
            </b-col>
            <b-col cols="2" class="mb-1">
              <b-form-checkbox size="sm" v-model="item.role" :value="r" :button-variant="item.role && ~item.role.indexOf(r) ? 'info' : 'light'" button>
                {{ r }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="ROLE_GROUP[r]" class="mb-1">
              <b-form-checkbox v-for="rr in ROLE_GROUP[r].split(',')" size="sm" class="mr-2" v-model="item.role" :value="rr" :key="rr"
                               :button-variant="item.role && ~item.role.indexOf(rr) ? 'success' : 'light'" button>
                {{ rr }}
              </b-form-checkbox>
            </b-col>
          </b-row>

        </b-col>
        <b-col cols="12" md="3" class="border-left">
          <img class="mw-100" :src="item.img || 'https://i.balaan.io/blank/noimg_goods_200.webp'" />

          <div class="hr-title"><b>Last Login</b></div>
          <hr/>
          <div v-if="item.last_login_dt">
            {{item.last_login_dt}} ({{item.last_login_ip}})
          </div>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="warning" @click="resetPW(item)" :disabled="busy.resetPW">
          비밀번호 초기화
          <b-spinner class="ml-1" small v-if="busy.resetPW"></b-spinner>
        </b-button>
        <b-button v-if="$R('ADMIN')" variant="outline-light" @click="$modal.show({title:'JSON 보기', html:'<pre>' + JSON.stringify(item, null, 2) + '</pre>'})">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="$modal.show({type:'diff', diff:item._diff})">
          수정이력
        </b-button>
        <b-button v-if="$R('USER_X')" variant="outline-danger" @click="removeUser(item)">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
        <b-button v-if="$R('USER_CS_W')" variant="primary" @click="ok()">
          저장
        </b-button>
      </template>
    </b-modal>


    <div class="d-flex align-items-center">
      <div class="ml-auto">
        <!--<b-button class="m-1" variant="success" @click="down('xlsx')">XLSX</b-button>
        <b-button class="m-1" variant="success" @click="down('txt')">Text</b-button>-->
        <b-button-group class="m-1">
          <b-button :variant="listType === 'list' ? 'dark' : 'light'" @click="listType = 'list'"><i class="fa fa-list"></i></b-button>
          <b-button :variant="listType === 'img' ? 'dark' : 'light'" @click="listType = 'img'"><i class="fa fa-picture-o"></i></b-button>
        </b-button-group>
      </div>
    </div>


    <b-card v-if="listType === 'img'">
      <div v-for="g in groups">
        <h5>{{teamMap[g.team].text}}</h5>
        <div class="d-flex align-items-center flex-wrap">
          <div v-for="u in g.users" class="flex-grow-0 m-1 w-150px">
            <div class="position-relative">
              <!-- 상단 정보 -->
              <div class="position-absolute" style="left:0;line-height:15px">
                <b-badge variant="primary">{{u.name}}</b-badge><br/>
              </div>
  <!--            <img :src="u.img || 'https://i.balaan.io/blank/noimg_review_200.webp'" class="w-100" @click="btnAction({item: u}, 'show_modal')"/>-->
              <img :src="u.img || 'https://i.balaan.io/blank/noimg_goods_200.webp'" class="center-cropped" @click="btnAction({item: u}, 'show_modal')"/>
              <!-- 하단 정보 -->
              <div class="position-absolute text-right" style="bottom:0;right:0">
                <b-badge variant="light">{{u.id}}</b-badge><br/>
              </div>
            </div>
            <div class="text-center mt-2">
              <b-btn size="sm" variant="success" class="mr-1" @click="btnAction({item: u}, 'show_modal')">상세</b-btn>
              <b-btn size="sm" variant="danger" class="" @click="btnAction({item: u}, 'remove')">삭제</b-btn>
            </div>
          </div>
        </div>
        <hr/>
      </div>
    </b-card>

    <c-table v-if="listType === 'list'" :table-data="items" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" :caption="items.length + ' 명'"
             @btn-clicked="btnAction"></c-table>
  </div>
</template>

<style scoped>
  .center-cropped {
    object-fit: cover;
    object-position: center; /* Center the image within the element */
    height: 150px;
    width: 150px;
  }
</style>

<script>
import {getT} from '@/shared/i18n'
import {DELIVER_BOARD_COLUMNS} from "balaan_constants";
import {down} from '@/shared/impexp'

const T = getT('en-US', 'Users');
const CHECK_COL = 'email,mobile,name,group,img,role'.split(',');

export default {
  name: 'CSUsers',
  title: '위탁사관리',
  data() {
    return {
      T,
      ROLE_GROUP: this.$C.ROLE_GROUP,
      ROLE_INFO: this.$C.ROLE_INFO,
      teamMap: this.$utils.arr2map(this.$C.TEAMS, 'value'),
      teams: this.$C.TEAMS.filter(e => e.value.in('ks,ubase,skms'.split(','))),
      form: {
        search: '',
        approved: 'ALL',
        teams: [],
        sort: '_cdt:-1',
        login_dt_from: '',
        login_dt_to: ''
      },
      listType: 'list',
      items: [],
      groups: [],
      item: {},
      item_org: {},
      fields: [
        {key: '_img40', label: '이미지', sortable: true, class: 'w-100px'},
        {key: 'html2', label: 'ID<br/>Name', sortable: true},
        {key: 'html5', label: '소속', sortable: true, class: 'w-170px'},
        {key: 'html6', label: '근무형태', sortable: true, class: 'text-center w-100px'},
        {key: 'html4', sortable: true},
        {key: 'html3', label: 'Join<br/>Last Login', sortable: true, class: 'text-center w-200px'},
        {key: 'html1', label: 'Roles', sortable: true},
        {
          key: '_actions2',
          label: '승인',
          buttons: [
            {label: '승인', variant: 'success', if: e => !e.approved, else: () => '승인됨', event: 'approve'}
          ],
          class: 'text-center w-100px'
        },
        {
          key: '_actions',
          label: 'Actions',
          buttons: [
            {label: '상세', variant: 'success', event: 'show_modal'},
            {label: '삭제', variant: 'danger', event: 'remove'}
          ],
          class: 'text-center w-125px'
        },
      ],
      perPage: 100,
      busy: {
        list: false,
        resetPW: false,
      },
      modal: {detail: false, random: false, randomResult: false},
      checked: true,

      randomLunchText: '',
      randomLunchResult: {},
      options: {
        sort: [
          {text: '가입일시', value: '_cdt:-1'},
          {text: '마지막로그인', value: 'last_login_dt:-1'},
          {text: 'ID', value: 'id:1'},
          {text: '이름', value: 'name:1'},
          {text: 'Email', value: 'email:1'},
        ],
        workType: [
          {text: '발라니어', value: 'balaaneer'},
          {text: '협력사', value: 'partner'},
          {text: '파견직', value: 'dispatch'},
          {text: '계약직/인턴', value: 'contract'},
          {text: '기타', value: 'etc'},
          {text: '없음', value: null},
        ],
        approved: [
          {text: '전체', value: 'ALL'},
          {text: '승인됨', value: 'Y'},
          {text: '미승인', value: 'N'},
        ]
      },
      optionMap: {}
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    this.form.teams = this.teams.map(e => e.value);
    this.optionMap.workType = this.$utils.arr2map(this.options.workType, 'value', 'text');

    this.list();
  },
  watch: {
    perPage: function () {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    async list() {
      this.busy.list = true;
      let j = await this.$api.postJson('/user/cs/list', this.form);
      this.busy.list = false;
      if (!j) return;

      const groupMulti = this.$utils.arr2multi(j.list, 'group');
      const groups = Object.entries(groupMulti).map(([team, users]) => ({
        team,
        users,
        idx: this.$C.TEAMS.findIndex(e => e.value === team),
      }));
      groups.sort((a, b) => a.idx - b.idx);
      this.groups = groups;
      j.list.forEach(e => {
        const team = this.teamMap[e.teamId] || {};
        const parent = this.teamMap[team.parentId];
        e.html1 = e.role.map(r => `<span class="badge badge-primary">${r}</span>`).join(' ');
        e.html2 = `${e.id}<br/>${e.name}`;
        e.html3 = `${e._cdt}<br/>${e.last_login_dt || ''}`;
        e.html4 = e.email_mask || e.email;
        e.html5 = (parent && parent.text !== '발란' ? `<span class="fs-11">${parent.text}</span><br/>` : '')
          + `${team.text || '-'}`;
        e.html6 = this.optionMap.workType[e.workType] || '-';
      });
      this.items = j.list;
    },
    toggleStatus() {
      this.form.teams = this.form.teams.length === this.teams.length ? [] : this.teams.map(e => e.value);
    },
    showModal(row) {
      this.modal.detail = true;
      this.item_org = row.item;
      this.item = this.$utils.clone(row.item);
    },
    async modalHide(event) {
      // console.log(event, event.trigger);
      let modifiedCols = CHECK_COL.filter(e => JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));
      if (event.trigger === 'ok') {
        if (modifiedCols.length) {
          event.preventDefault && event.preventDefault();
          let item = {id: this.item.id};
          modifiedCols.forEach(e => item[e] = this.item[e]);
          let j = await this.$api.postJson('/user/cs/update', {item});
          if (j) {
            this.list();
            this.modal.detail = false;
          }
        }
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        if (modifiedCols.length) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
              event.preventDefault();
            }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    async btnAction(row, event) {
      let obj = row.item, j;
      if (event === 'approve') {
        if (!confirm(`정말로 [${obj.name}] 사용자를 승인하시겠습니까?`)) return;
        j = await this.$api.postJson('/user/cs/approve', {id: obj.id});
      } else if (event === 'remove') {
        if (!confirm(`정말로 [${obj.name}] 사용자를 삭제하시겠습니까?`)) return;
        j = await this.$api.postJson('/user/cs/remove', {id: obj.id});
      } else if (event === 'show_modal') {
        this.showModal(row);
      }
      if (j) this.list();
    },
    async resetPW(obj) {
      if (!confirm(`비밀번호가 랜덤한 문자열로 초기화되며, 해당 사용자는 로그아웃 됩니다. 진행하시겠습니까?`)) return;
      this.busy.resetPW = true;
      const j = await this.$api.postJson('/user/cs/resetPW', {id: obj.id});
      this.busy.resetPW = false;
      if (j) {
        this.$utils.alert(`ID: ${obj.id}, 비밀번호: ${j.plain}<br/>비밀번호를 바로 변경하도록 안내해주세요`);
      }
    },
    async removeUser(obj) {
      if (!confirm(`정말로 [${obj.name}] 사용자를 삭제하시겠습니까?`)) return;
      let j = await this.$api.postJson('/user/cs/remove', {id: obj.id});
      if (j) this.list();
    },
    async down(type) {
      const selectedItems = this.items.filter(e=>e.selected);
      if (!selectedItems.length) return alert('다운받을 사용자를 선택해주세요');

      let name = DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.name);
      let code = DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.code);
      name = ['API주문번호','API주문결과','API주문일시','API주문에러'].concat(name);
      code = ['api_orderno','result','_dt','msg'].concat(code);

      down(selectedItems, name, code, `CS_Users_${this.$utils.dt()}`, type);
    },
  }
}
</script>

<style scoped>
div.hr-title {
  margin-top: 8px;
  margin-bottom: -10px;
}
</style>
